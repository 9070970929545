import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './assets/tailwind.css';
import { inject } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';


inject();

injectSpeedInsights({
  sampleRate: 1.0, 
  beforeSend: (data) => {
    if (data.url.includes('/sensitive-path')) {
      return null; 
    }
    return data;
  },
  debug: false,
});

createApp(App).use(router).mount('#app');
